<template v-if="config.bannerNotification">
	<div>
		<!-- Banner notification -->
		<v-alert
			v-if="config.bannerNotification"
			type="info"
			outlined
			dense
			:prominent="user.c_aanioikeus_2025_vaalissa"
			:icon="user.c_aanioikeus_2025_vaalissa ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline'"
		>
			<v-row
				align="center"
				no-gutters
			>
				<strong v-if="config.bannerNotification.title">
					{{ config.bannerNotification.title }}
				</strong>
				<p v-if="config.bannerNotification.summary">
					{{ config.bannerNotification.summary }}
				</p>
			</v-row>
			<v-divider
				class="mb-2 white"
			/>
			<v-row
				align="center"
				no-gutters
			>
				<v-col
					v-for="(link, index) in config.bannerNotification.links"
					:key="index"
					cols="4"
				>
					<v-btn
						class="button"
						size="small"
						color="secondary"
						:href="link.link"
						target="_blank"
					>
						{{ link.title }}
					</v-btn>
				</v-col>
			</v-row>
		</v-alert>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'BannerNotification',
	computed: {
		...mapState([
			'user',
			'config',
		]),
	},
}

</script>
