<i18n>
{
	"en": {
		"card": {
			"editYourDetails": "Edit your profile"
		},
		"invoiceNotification": {
			"text": "Your latest membership invoice is late.",
			"buttonText": "Show invoices"
		},
		"openInvoices": {
			"title": "Open invoices",
			"noItemsText": "You have no open invoices"
		},
		"subchapters": {
			"title": "Membership",
			"noItemsText": "You are not in any section."
		}
	},
	"fi": {
		"card": {
			"editYourDetails": "Muokkaa tietojasi"
		},
		"invoiceNotification": {
			"text": "Viimeisin jäsenmaksusi on maksamatta.",
			"buttonText": "Näytä laskut"
		},
		"openInvoices": {
			"title": "Avoimet laskut",
			"noItemsText": "Sinulla ei ole avoimia laskuja"
		},
		"subchapters": {
			"title": "Jäsenyydet",
			"noItemsText": "Et ole minkään osaston jäsen"
		}
	}
}
</i18n>

<template>
	<div>
		<v-container
			class="container--narrow"
			v-if="config.bannerNotification"
		>
			<BannerNotification />
		</v-container>
		<!-- Membership card -->
		<v-container class="container--narrow">
			<v-card>
				<v-img
					v-if="config._hero_image"
					class="white--text align-end"
					aspect-ratio="1.77777777"
					:src="config._hero_image.url"
					dark
				/>
				<v-card-text>
					<v-alert
						v-if="memberHasLateInvoice === true"
						type="error"
					>
						{{ $i18n.t('invoiceNotification.text') }}
						<div class="mt-3">
							<v-btn
								text
								:to="{ name: 'invoiceContainer' }"
							>
								{{ $i18n.t('invoiceNotification.buttonText') }}
							</v-btn>
						</div>
					</v-alert>
					<FilterableList
						v-if="user._details && user._details.length"
						:items="user._details"
						subtitle-src="label"
						title-src="value"
						:enable-click="false"
						:enable-search="false"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						:to="{ name: 'myAccount' }"
						color="primary"
						text
					>
						<v-icon left>
							mdi-pencil
						</v-icon>
						{{ $i18n.t('card.editYourDetails') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>

		<!-- Subchapters -->
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>{{ $i18n.t('subchapters.title') }}</v-card-title>
				<v-card-text>
					<FilterableList
						v-if="user._local_section_and_subsection_details"
						icon-src="_icon"
						:items="user._local_section_and_subsection_details"
						:enable-click="false"
						:enable-search="false"
					/>
				</v-card-text>
			</v-card>
		</v-container>

		<!-- Invoices -->
		<v-container class="container--narrow">
			<v-card>
				<v-card-title>{{ $i18n.t('openInvoices.title') }}</v-card-title>
				<v-divider />
				<v-card-text>
					<InvoiceList
						:items="openInvoices"
						:no-items-text="$i18n.t('openInvoices.noItemsText')"
					/>
				</v-card-text>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'
import BannerNotification from '@/components/BannerNotification'

export default {
	name: 'MembershipCard',
	components: {
		BannerNotification,
	},
	computed: {
		...mapState([
			'config',
			'user',
			'benefits',
		]),
		memberHasLateInvoice () {
			if (!this.user.invoice || !this.user.invoice.length) return []

			return this.user.invoice.findIndex(item => item._is_late === true) > -1
		},
		openInvoices () {
			if (!this.user.invoice || !this.user.invoice.length) return []

			return this.user.invoice.filter(item => item._is_paid === false)
		},
	},
	mounted () {
		this.$api.Me.doRequest()
	},
}
</script>
